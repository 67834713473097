.container {
  margin-top: 20px;
}
/* .MuiTableHead-root{

  background-color: blue;
} */

body {
  overflow-x: hidden !important;
}

.logincard {
  padding: 1.5em;
  /* padding-bottom: 1.2em; */
  background-color: white !important;
  /* box-shadow: none !important; */
  border: 1px solid black !important;
}

.childcontainer {
  margin-top: 50px !important;
}
.logo {
  /* height: 79px;
  width: 83px; */
}
.nodatafound {
  margin-top: 32px;
  text-align: center;
  color: #202557;
}
.addtocart {
  background-color: #202557 !important;
  color: white !important;
  padding: 0.5em 2em !important;
  border-radius: 1.5em !important;
}
.addtocart:hover {
  transform: scale(1.05) translateZ(0px);
}

.Orderbutton {
  background-color: #202557 !important;
  padding: 0.5em 1em !important;
  border-radius: 1.5em !important;
  color: white !important;
}
.Orderbutton:hover {
  transform: scale(1.05) translateZ(0px);
}
.deleteicon:hover {
  transition: ease-in;
  transform: scale(1.3);
  color: red;
}
.infoicon:hover {
  transition: ease-in;
  transform: scale(1.3);
}
.attachicon:hover {
  transition: ease-in;
  transform: scale(1.3);
}
.Disputebton {
  background-color: #202557 !important;
  padding: 0.5em 1em !important;
  border-radius: 1.5em !important;
  color: white !important;
  float: right;
}
.DisputeforBtn {
  background-color: #202557 !important;
  padding: 0.5em 1em !important;
  border-radius: 1.5em !important;
  color: white !important;
}
.DisputeforBtn:hover {
  transform: scale(1.05) translateZ(0px);
}
.Disputebton:hover {
  transform: scale(1.05) translateZ(0px);
}
.tooltipbg {
  background-color: white;
  border: 1px black;
  /* height: 100px; */
}

.grapheader {
  color: #202557 !important;
  font-weight: bold !important;
  font-size: larger !important;
  text-align: center;
}
.cnfrmbtn {
  cursor: pointer;
  background-color: #4caf50 !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #4caf50 !important;
  margin-left: 3% !important;
  padding: 12px 30px;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  border: none;
  color: white;
  border-radius: 3px;
}
.ordFulSearch {
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowicon {
  background-color: #1b9093;
  color: white;
  margin-right: 6px;
  border-radius: 6px;
}
.arrowicon svg {
  color: white;
}

.addsearch {
  position: absolute;
}

.accordion {
  width: calc(100% - 30px);
}

.alignCenter {
  text-align: center;
}

.pleft-20 {
  padding-left: 20px;
}

.pleft-5 {
  padding-left: 5px;
}

.pleft-10 {
  padding-left: 10px;
}

.hrLine {
  width: calc(100% - 30px);
  background-color: #ded0d0;
  height: 1px;
  margin: 20px 0;
}

.MuiTableCell-root {
  color: #202557 !important;
}
.search {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #202557;
}
.searchicon {
  margin-top: 24px;
}
.shoppingcart {
}

.MuiTableCell-body {
  color: #202557 !important;
}
.tablecell {
  color: "pink";
}
.mtop-20 {
  margin-top: 20px;
}

.bold {
  font-weight: bold !important;
}

.mbtm-10 {
  margin-bottom: 10px !important;
}

.mbtm-20 {
  margin-bottom: 20px !important;
}

.mbtm-30 {
  margin-bottom: 30px !important;
}

.mbtm-40 {
  margin-bottom: 40px !important;
}

.mtop-10 {
  margin-top: 10px !important;
}

.mtop-20 {
  margin-top: 20px !important;
}

.mtop-30 {
  margin-top: 30px !important;
}

.mtop-40 {
  margin-top: 40px !important;
}

.attachmentrow {
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  justify-content: space-between;
}

.attachmentrow div {
  margin-right: 10px;
}

.tablePadding {
  padding: 10px !important;
}

.alignLabel {
  display: -ms-flexbox;
  flex-direction: row;
  align-items: center;
  /* margin-top: 13px; */
}

.asterisk::after {
  content: "*";
  color: red;
}

.alignLabelcenter {
  display: -ms-flexbox;
  flex-direction: row;
  align-items: center;
  margin-top: 13px !important;
}
.alignLabelCostReport {
  margin: auto !important;
  text-align: center;
}

.costReportDate {
  margin-top: 10px;
  margin-bottom: 25px;
}

.alignLabelEnd {
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  align-items: flex-end;
}

.pointer {
  cursor: pointer;
}

.tField {
  padding: 1px !important;
}

.selectStyle div span {
  display: none;
}

.red {
  color: #ed1b24;
}

.errormessagecolor {
  margin-bottom: 10px;
  margin-top: -10px;
  color: red;
}

.ReactTable * {
  box-sizing: border-box;
  color: #202557;
}

.logoutbtn {
  color: white !important;
  background-color: #202557 !important ;
}

.spinner {
  top: 50%;
  left: 50%;
  z-index: 999;
  position: absolute;
  margin-left: -50px;
  margin-right: -50px;
  height: 100px;
  width: 100px;
}

.innerspinner {
  /* width:inherit !important;
  height:inherit !important */

  color: #202557 !important;
}

/* .inventoryContainer{

  margin-top: 20px  !important;
} */
.searchinput {
}

.loginerror {
  margin: 0 auto;
  margin-top: 10px;
  color: red;
}
* {
  font-family: "Helvetica" !important;
}
.chip {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
}

.totalHdr {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  flex-direction: row;
}

.overflow {
  overflow: auto !important;
}
.dialogcontent {
  color: #202557 !important;
}

.additionalInfoItems {
  margin-bottom: 10px;
  font-size: 0.875rem;
}
.navIcon {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.buttonRight {
  display: flex;
  display: -ms-flexbox;
  justify-content: flex-end;
}

.ordertable {
  margin-top: -43px !important;
}
.arContainer {
  margin-top: -43px !important;
  padding-left: 12px;
  padding-right: 12px;
}

.react-select__control :hover {
  border-color: #202557 !important;
}
.dialogboxheader {
  text-align: center;
  color: #202557;
}
.centeralign {
  z-index: 4;
  font-size: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #202557;
}
.ordertextcolor {
  color: #202557 !important;
  font-weight: bold !important;
  font-size: 0.875rem !important;
  /* text-align: center; */
}
.reporttextcolor {
  color: #202557 !important;
  font-weight: bold !important;
  font-size: larger !important;
  /* text-align: center; */
}

.logo{
  /* width: 127px; */
  height: 32px;
}
.search_icon {
  font-size: 46px !important;
  color: #1b9093 !important;
}

.totalprice {
  font-size: smaller;
}
.costsTopBar {
  padding-left: 2.5em;
}
.ordertopbar {
  /* display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center; */
  /* margin-top: 51px !important; */
  padding-left: 1.5em;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: green !important;
  stroke-linecap: round !important;
  transition: stroke-dashoffset 0.5s ease 0s !important;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: green !important;
  font-size: 20px;
  dominant-baseline: middle !important;
  text-anchor: middle !important;
}
.CostSavingContainer {
  margin-top: -43px !important;
  padding-left: 12px;
  padding-right: 12px;
}
.personalCard {
  color: #202557;
  font-weight: 700;
}
.personalinfo {
  color: #202557;
  white-space: pre-wrap;
  margin-left: 5px;
}
.testing {
  white-space: pre-wrap;
}
.DeviceCard {
  padding-left: inherit;
}
.AgreementQuantity {
  display: none !important;
}
.location {
  color: #202557;
  margin-left: 20%;
}

.mobilewrap {
  display: none;
}

.leftnopad {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 15px !important;
}

.rightnopad {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 15px !important;
  padding-right: 0 !important;
}

.rowAlign {
  display: flex;
  display: -ms-flexbox;
  flex-direction: row;
  justify-content: space-between;
}

.rowAlign .selectStyle {
  width: 40%;
}

.personalinfoflex {
  display: flex;
}

.personalInfoAccessLevel {
  white-space: nowrap;
}

.alertbottommargin {
  margin-bottom: 10px !important;
}

.devicediv {
  margin-bottom: 15px;
}

.logouticon {
  color: #202557;
}

.logouticonblack {
  color: black;
  margin-right: 0px !important;
}

.logoutdiv {
  margin-left: -7px;
}

.additionalInfoTitle {
  color: #202557;
  font-weight: bold;
  font-size: 0.875rem;
}

@media (max-width: 959px) {
  .logincard {
    width: 90% !important;
  }

  .mobilewrap {
    display: block;
  }
  .mbtmCard {
    margin-bottom: 30px !important;
  }
  .profile {
    padding: 0px !important;
    height: unset !important;
    width: unset !important;
    border-radius: 0px !important;
    background-color: inherit !important;
    box-shadow: none !important;
    color: black !important;
  }

  .personalinfoflex {
    display: block;
  }

  .personalinfo {
    margin-left: 0px;
  }
}

.hint {
  background: #494949;
  color: #f8f8f9;
  font-size: 10px;
  padding: 10px;
  position: absolute;
  width: 140px;
  font-weight: bold;
  bottom: 315px;
  left: 450px;
  font-size: 12px;
}

.shiptoLocationGraph {
  overflow-y: scroll;
}

.shiptoLocationGraph > svg {
  height: 600px;
}

.purchaseQtyGraph {
  overflow-y: scroll;
}

.purchaseQtyGraph > svg {
  height: 450px;
}

.purchaseValueGraph {
  overflow-y: scroll;
}

.purchaseValueGraph > svg {
  height: 450px;
}

.loginsuccess {
  margin: 0 auto;
  margin-top: 10px;
  color: green;
}
.accountable {
  margin-top: 3%;
  margin-left: 3%;
}
.customercard {
  margin-left: 1%;
}
.personcard {
  margin-left: -3%;
}
.attcard {
  margin-top: -23% !important;
  margin-left: -3%;
}
.changepwdform {
  margin-left: 28%;
}

.labelNoWrap {
  white-space: nowrap;
}

@media (max-width: 330px) {
  .logincard {
    padding: 1em;
  }
}
@media (min-width: 360px) and (max-width: 640px) {
  .changepwdform {
    margin-left: 10%;
  }
}
@media (min-width: 411px) and (max-width: 731px) {
  .changepwdform {
    margin-left: 17%;
  }
}
@media (min-width: 320px) and (max-width: 568px) {
  .changepwdform {
    margin-left: 15%;
  }
}
@media (min-width: 375px) and (max-width: 667px) {
  .changepwdform {
    margin-left: 19%;
  }
}
@media (min-width: 414px) and (max-width: 736px) {
  .changepwdform {
    margin-left: 21%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .changepwdform {
    margin-left: 15%;
  }
}
.cartlength {
  color: white;
  margin-top: -28px;
  font-size: 11px;
  background-color: red;
  border-radius: 25px;
  height: 14px;
  width: 100%;
  margin-left: -17px;
  text-align: center;
}

.helperText {
  width: max-content !important;
}

.orderSubmit {
  text-align: end;
  position: relative;
  left: 60em;
}

.mleft-10 {
  margin-left: 10px !important;
}
.mright-10 {
  margin-right: 10px !important;
}

.padding4px {
  padding: 4px !important;
}

.printpage {
  display: none;
}

@media print {
  .printpage {
    display: block;
  }
  .ordercart {
    background-color: red;
  }

  .printpage .check {
    text-align: center;
    /* display: none; */
    color: red;
  }
}

.barchart svg {
  height: 350px;
}
.tblColthHide {
  font-weight: bold !important;
  padding: 10px !important;
}

@media (max-width: 500px) {
  .tblColHide {
    display: none !important;
  }
  .tblColthHide {
    display: none !important;
  }
}
.networkerr {
  font-size: larger;
  background-color: steelblue;
  text-align: center;
  color: white;
}

.topbartext {
  display: flex;
  justify-content: center;
  flex: 1;
  margin-left: 17em;
}

@media (max-width: 400px) {
  .topbartext {
    display: flex;
    justify-content: center;
    flex: 1;
    margin-left: 3em;
  }
}
