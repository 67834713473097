.loginBtn {
  padding: 0.5em 3em !important;
  border-radius: 1.5em !important;
  background-color: #202557 !important;
  color: white !important;
  /* margin-top: 10% !important; */
}
.loginBtn:hover {
  transform: scale(1.05) translateZ(0px);
}

.rejBtn {
  padding: 0.5em 3em !important;
  border-radius: 1.5em !important;
  margin: 5px 10px !important;
  background-color: #2f2f77 !important;
  color: white !important;
}

.moreBtn {
  padding: 0.5em 3em !important;
  border-radius: 1.5em !important;
  margin: 5px 10px !important;
  background-color: #45454a !important;
  color: white !important;
}

.forgotPwd a {
  color: #202557 !important;
  cursor: pointer;
}
.forgotPwd a:hover {
  color: #202557 !important;
}

.a {
  color: #202557 !important;
}
.lang {
  margin: 1em 0;
  color: white;
  cursor: pointer;
}

.logoheader {
  margin-bottom: 50px;
  margin-top: 19px;
  text-align: center;
}

.logoheader img {
  width: 200px;
}
.usericon {
  color: #202557;
}
.snackbar {
  margin-top: 20px;
}
