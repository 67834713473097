.MuiIconButton-root {
    padding:0px !important;
}

.MuiInputAdornment-positionEnd {
    margin-left: 0px !important;
}

.MuiOutlinedInput-adornedEnd {
    padding-right: 5px !important;
}